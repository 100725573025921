// @ts-nocheck
'use strict';

/** @param {HTMLElement} rootElem */
function DealerBusinessCard(rootElem) {
    if (! (rootElem instanceof HTMLElement)) {
        throw new Error('expected root elem to be of type HTMLElement');
    }
    this.rootElem = rootElem;
}

DealerBusinessCard.prototype = {
    setup: function () {
        this.handleImprint();
        this.setupCarousel();
        this.setupNavigationAnimation();
        this.setupStickyHeader();
        this.setTitle();
        this.handleTosLink();
    },
    setupCarousel: function () {
        const searchInput = document.getElementById('js-searchTeamInput');
        const searchInputButton = document.getElementById('js-searchTeamButton');
        const teamshopNavButton = document.getElementById('js-teamshopNavButton');
        const hiddenItems = document.getElementById('scrollbarTeamsHidden');
        const scrollbarWrapper = document.getElementById('scrollbarTeams');

        function generateScrollbar() {
            let itemsXS = 2;
            let itemsSM = 3;
            let itemsMD = 4;
            let itemsLG = 5;
            let scrollbar = $('#scrollbarTeams');

            if (itemsLG < itemsMD) {
                itemsMD = itemsLG;
            }

            if (itemsLG < itemsSM) {
                itemsSM = itemsLG;
            }

            if (itemsLG < itemsXS) {
                itemsXS = itemsLG;
            }

            scrollbar.owlCarousel({
                loop: true,
                margin: 10,
                nav: false,
                dots: false,
                autoplay: true,
                autoHeight: true,
                autoWidth: false,
                navText: ['<i class="icon icon-angle-left"></i>', '<i class="icon icon-angle-right"></i>'],
                autoplayHoverPause: true,
                center: true,
                autoPlay: 2500,
                autoplaySpeed: 1000,
                navSpeed: 1000,
                responsive: {
                    0: {
                        items: itemsXS,
                    },
                    768: {
                        items: itemsSM,
                    },
                    1024: {
                        items: itemsMD,
                    },
                    1250: {
                        items: itemsLG,
                    },
                },
            });

            scrollbar.on('changed.owl.carousel', function onOwlChanged(evt) {
                if (teamshopNavButton) {
                    if(evt.relatedTarget.$stage !== undefined) {
                        const currChild = evt.relatedTarget.$stage.children()[evt.item.index];
                        if(currChild) {
                            const item = currChild.getElementsByClassName('scrollbar__item')[0];
                            if(item) {
                                if(item.dataset) {
                                    const currUri = item.dataset.uri;
                                    if(currUri) {
                                        teamshopNavButton.href = currUri;
                                        teamshopNavButton.style.display = '';
                                    }
                                }
                            }
                        }
                    }else{
                        teamshopNavButton.style.display = 'none';
                    }
                }
            });

            return scrollbar;
        }

        function filterScrollbar(scrollbarItems,value) {
            scrollbar.owlCarousel('destroy');
            let valueSearch = value.toLowerCase();
            for(let item of scrollbarItems) {
                let searchText = item.dataset.searchText.toLowerCase();
                if(! searchText.includes(valueSearch)) {
                    hiddenItems.appendChild(item);
                }
            }
            for(let item of hiddenItems.querySelectorAll('.scrollbar__item')) {
                let searchText = item.dataset.searchText.toLowerCase();
                if(searchText.includes(valueSearch)) {
                    scrollbarWrapper.appendChild(item);
                }
            }
            return generateScrollbar();
        }

        let scrollbar = generateScrollbar();

        if(searchInput && searchInputButton) {
            if(scrollbar) {
                const scrollbarItems = scrollbar[0].querySelectorAll('.scrollbar__item');
                if(scrollbarItems) {
                    searchInputButton.addEventListener('click',function () {
                        scrollbar = filterScrollbar(scrollbarItems,searchInput.value);
                    });
                    searchInput.addEventListener('keyup',function (e) {
                        if(e.key === 'Enter' || e.keyCode === 13) {
                            scrollbar = filterScrollbar(scrollbarItems,searchInput.value);
                        }
                    });
                }
            }
        }
    },
    setupStickyHeader: function () {
        const mn = $('.headerDealerCard');
        const mns = 'sticky';
        const hdr = 0;

        checkSticky(mn, mns, hdr);

        window.addEventListener('scroll', () => checkSticky(mn, mns, hdr));
        window.addEventListener('resize', () => checkSticky(mn, mns, hdr));
    },
    setupNavigationAnimation: function () {
        $('.headerDealerCardNavigation a[href^="#"]').on('click', function onNavClick(e) {
            var href = $(this).attr('href');
            if ($(href).length > 0) {
                var top = $(href).offset().top;
                var heightHeader = $('.headerDealerCard').outerHeight();
                $('html,body').animate({
                    scrollTop: top - heightHeader,
                }, 800);
                e.preventDefault();
            }
        });
    },
    setTitle: function () {
        const dataElem = document.getElementById('js-googleData');
        if (dataElem.dataset.title) {
            document.title = dataElem.dataset.title;
        }
    },
    handleTosLink: function () {
        let customTosLink = this.rootElem.dataset.customTosLink;
        if (typeof customTosLink !== 'string') {
            return;
        }
        customTosLink = customTosLink.trim();
        if (! customTosLink) {
            return;
        }
        const footer = document.querySelector('.footerBottom .footerBottomNavigation');
        const footerAnchors = footer.getElementsByTagName('a');
        const pathRegex = new RegExp('^.*/(agb|general-terms)/?$');
        // eslint-disable-next-line no-useless-escape
        const hostnameRegex = new RegExp('^(www\.)?(jako\.(com|de)|.*dc-test\.de)$');
        for (const anchor of footerAnchors) {
            const url = new URL(anchor.href);
            if (hostnameRegex.test(url.hostname) && pathRegex.test(url.pathname)) {
                anchor.href = customTosLink;
                break;
            }
        }
    },
    handleImprint() {
        const modalImprint = '#modalImprint';
        if (document.querySelectorAll(modalImprint).length !== 1) {
            return;
        }
        document.querySelectorAll('.js-navigation_imprint').forEach((item) => {
            item.removeAttribute('href');
            item.addEventListener('click', () => {
                jQuery(modalImprint).modal();
            });
        });
    }
};

window.addEventListener('load', () => {
    const rootElem = document.getElementById('dealerBusinessCard');
    const dealerBusCard = new DealerBusinessCard(rootElem);
    dealerBusCard.setup();
});

$('.team-carousel').owlCarousel({
    loop: true,
    center: true,
    autoWidth: true,
    nav: true,
    dots: false,
    navText: ['<i class="icon icon-arrow-left"></i>', '<i class="icon icon-arrow-right"></i>'],
    margin: 0,
    responsiveClass: true,
    items: 1,
});
